import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import store from "./store/index";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "typeface-roboto";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Admin from "./admin/Admin";
import Home from "./pages/Home";
import PollResults from "./pages/PollResults";
import QuestionsViewer from "./pages/QuestionsViewer";

render(
  <Provider store={store}>
    <Router>
      <Route exact path="/" component={Home} />
      <Route exact path="/live/:hashkey" component={Home} />
      <Route exact path="/ozadmin" component={Admin} />
      <Route exact path="/ozadmin/:page" component={Admin} />
      <Route exact path="/pollresults" component={PollResults} />
      <Route exact path="/questionsviewer" component={QuestionsViewer} />
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
