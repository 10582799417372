import React from "react";
import { connect } from "react-redux";

import { setState } from "../store/actions";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import EditModal from "./EditModal";
import SaveIcon from "@material-ui/icons/Save";
import { format } from "date-fns";

class DataTable extends React.Component {
  addRow() {
    this.props.setState({
      showEditModal: true,
      editTitle: `Add ${this.props.singular}`,
      editColumns: this.props.columns,
      editRow: { id: 0 }
    });
  }

  editRow(editRow) {
    this.props.setState({
      showEditModal: true,
      editTitle: `Edit ${this.props.singular}`,
      editColumns: this.props.columns,
      editRow
    });
  }

  getField(row, column, texoOnly) {
    if (column.type === "datetime")
      return row[column.id]
        ? format(new Date(row[column.id] * 1000), "dd/MM/yyyy HH:mm")
        : null;
    if (column.type === "checkbox")
      return row[column.id] && !texoOnly ? <CheckIcon /> : null;
    if (column.type === "custom") return this.props.getCustomField(row, column);
    if (column.type === "list") {
      const listrow = column.list.filter(l => l.id === row[column.id]);
      return listrow.length > 0 ? listrow[0].descr : null;
    }
    return row[column.id];
  }

  exportFile() {
    const { columns, list, title, searchFields, searchText } = this.props;

    const header = `${columns
      .filter(c => c.label !== "")
      .map(c => c.label)
      .join(";")}\n`;

    const txts = list
      .filter(
        a =>
          (searchFields || [])
            .map(f => a[f])
            .join(" ")
            .toUpperCase()
            .indexOf(searchText.trim().toUpperCase()) >= 0
      )
      .map(
        row =>
          `${columns
            .filter(c => c.label !== "")
            .map(column => this.getField(row, column, true))
            .join(";")}`
      )
      .join(`\n`);

    const universalBOM = "\uFEFF";
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," +
        encodeURIComponent(universalBOM + header + txts)
    );
    element.setAttribute("download", `${title}.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  render() {
    const { columns, list, title } = this.props;

    return (
      <Paper style={{ flexGrow: 1, margin: "20px" }}>
        <h2 style={{ padding: "20px" }}>
          {title} {this.props.titleButton}
          {
            <span style={{ float: "right" }}>
              <TextField
                size="small"
                style={{ width: "200px" }}
                onChange={event =>
                  this.props.setState({ searchText: event.target.value })
                }
                value={this.props.searchText || ""}
                label="Search"
                variant="outlined"
                autoComplete="off"
              />
              <IconButton onClick={() => this.exportFile()}>
                <SaveIcon />
              </IconButton>
              <IconButton onClick={() => this.addRow()}>
                <AddIcon />
              </IconButton>
            </span>
          }
        </h2>
        {this.props.subBox}
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell />
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {list
                .filter(
                  a =>
                    (this.props.searchFields || [])
                      .map(f => a[f])
                      .join(" ")
                      .toUpperCase()
                      .indexOf(this.props.searchText.trim().toUpperCase()) >= 0
                )
                .map((row, idx) => (
                  <TableRow key={`row${idx}`}>
                    <TableCell>{`${idx + 1}.`}</TableCell>

                    {columns.map(column => (
                      <TableCell
                        key={`${idx}-${column.id}`}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {this.getField(row, column, false)}
                      </TableCell>
                    ))}
                    <TableCell>
                      <IconButton onClick={() => this.editRow(row)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <EditModal
          crud={this.props.crud}
          listName={this.props.listName}
          deletable={this.props.deletable}
        />
      </Paper>
    );
  }
}
DataTable.defaultProps = {
  searchText: ""
};

const mapStateToProps = state => {
  return {
    searchText: state.searchText
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
