import React from "react";
import { setState } from "../store/actions";
import { connect } from "react-redux";
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Divider,
  Button,
  Typography,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  Container
} from "@material-ui/core";
import { loadData, crud } from "../lib/api";
import { format } from "date-fns";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone";
import RestoreFromTrashTwoToneIcon from "@material-ui/icons/RestoreFromTrashTwoTone";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";

class QuestionsViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      status: 0,
      lock: ""
    };
    setInterval(() => this.loadQuestions(), 10000);
  }

  componentDidMount() {
    this.loadQuestions();
  }

  setStatus(id, status) {
    const question = {
      ...this.props.questionsList.find(q => q.id === id),
      status
    };
    crud("questions/", id, question).then(res => {
      this.loadQuestions();
    });
  }

  loadQuestions() {
    loadData("data/questions", "questionsList");
  }

  formatDate(ts) {
    return ts ? format(new Date(ts * 1000), "dd/MM HH:mm") : null;
  }

  render() {
    const list = this.props.questionsList.sort((a,b) => b.likes - a.likes);

    const statuses = ["New", "Approved", "Deleted", "Completed"];
    const lstat = [];
    if (this.props.showDeleted) lstat.push(2);
    if (this.props.showNewQuestions) lstat.push(0);
    if (this.props.showApproved) lstat.push(1);
    if (this.props.showCompleted) lstat.push(3);
    return (
      <Container
        style={{
          color: "black",
          marginTop: "5vh",
          textAlign: "center",
          height: "90vh"
        }}
      >
        <Grid container spacing={1}>
          {lstat.map((ls, lidx) => (
            <Grid
              item
              xs
              style={{ color: "black", padding: "10px", textAlign: "left" }}
            >
              <Typography>{statuses[ls]} Questions</Typography>
            </Grid>
          ))}
        </Grid>
        <Grid container style={{ height: "80vh" }} spacing={1}>
          {lstat.map((ls, lidx) => (
            <Grid item xs style={{ height: "100%" }}>
              <Paper key={lidx} style={{ height: "100%", overflow: "auto" }}>
                <List>
                  {list
                    .filter(l => l.status === ls)
                    .map((h, idx) => (
                      <React.Fragment key={idx}>
                        <ListItem>
                          {ls === 0 && (
                            <ListItemIcon>
                              <IconButton
                                edge="start"
                                onClick={() => this.setStatus(h.id, 2)}
                              >
                                <DeleteTwoToneIcon />
                              </IconButton>
                            </ListItemIcon>
                          )}
                          {ls === 1 && (
                            <ListItemIcon>
                              <IconButton
                                edge="start"
                                onClick={() => this.setStatus(h.id, 0)}
                              >
                                <HighlightOffTwoToneIcon />
                              </IconButton>
                            </ListItemIcon>
                          )}
                          {ls === 3 && (
                            <ListItemIcon>
                              <IconButton
                                edge="start"
                                onClick={() => this.setStatus(h.id, 1)}
                              >
                                <HighlightOffTwoToneIcon />
                              </IconButton>
                            </ListItemIcon>
                          )}
                          <ListItemText
                            primary={h.question}
                            secondary={
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textPrimary"
                                >
                                  {`${h.attendee_name} ${h.attendee_surname}`}
                                </Typography>
                                {` — ${this.formatDate(h.ts)}`}
                                {` — ${h.likes} likes`}
                              </>
                            }
                          />
                          {ls === 0 && (
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                onClick={() => this.setStatus(h.id, 1)}
                              >
                                <CheckCircleTwoToneIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          )}
                          {ls === 1 && (
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                onClick={() => this.setStatus(h.id, 3)}
                              >
                                <CheckCircleTwoToneIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          )}
                          {ls === 2 && (
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                onClick={() => this.setStatus(h.id, 0)}
                              >
                                <RestoreFromTrashTwoToneIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          )}
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>
                    ))}
                </List>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <div style={{ height: "60px", padding: "10px" }}>
            <Button
              style={{ marginLeft: "20px" }}
              variant="contained"
              color={this.props.showDeleted ? "primary" : "default"}
              onClick={() =>
                this.props.setState({ showDeleted: !this.props.showDeleted })
              }
            >
              {this.props.showDeleted ? "Hide " : "Show "}Deleted
            </Button>
            <Button
              style={{ marginLeft: "20px" }}
              variant="contained"
              color={this.props.showNewQuestions ? "primary" : "default"}
              onClick={() =>
                this.props.setState({
                  showNewQuestions: !this.props.showNewQuestions
                })
              }
            >
              {this.props.showNewQuestions ? "Hide " : "Show "}New Questions
            </Button>
            <Button
              style={{ marginLeft: "20px" }}
              variant="contained"
              color={this.props.showApproved ? "primary" : "default"}
              onClick={() =>
                this.props.setState({
                  showApproved: !this.props.showApproved
                })
              }
            >
              {this.props.showApproved ? "Hide " : "Show "}Approved
            </Button>
            <Button
              style={{ marginLeft: "20px" }}
              variant="contained"
              color={this.props.showCompleted ? "primary" : "default"}
              onClick={() =>
                this.props.setState({
                  showCompleted: !this.props.showCompleted
                })
              }
            >
              {this.props.showCompleted ? "Hide " : "Show "}Completed
            </Button>
        </div>
      </Container>
    );
  }
}

QuestionsViewer.defaultProps = {
  questionsList: [],
  showApproved: true,
  showDeleted: false,
  showNewQuestions: true,
  showCompleted: false
};

const mapStateToProps = state => {
  return Object.keys(QuestionsViewer.defaultProps).reduce(
    (obj, key) => ({ ...obj, [key]: state[key] }),
    {}
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsViewer);
