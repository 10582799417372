import React from "react";
import { connect } from "react-redux";

import { Grid, List, ListItem, ListItemIcon, IconButton, ListItemText, Divider, Button, DialogContent, DialogContentText, Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import { setState } from "../store/actions";
import { post } from "../lib/api";
import AddBoxIcon from '@material-ui/icons/AddBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

class Elections extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps, prevState) { }

    setVote() {

        const { elections: { hashkey } } = this.props.attendeeInfo;

        const votes = this.isDisabled(2) ? this.props.selectedCandidates.filter(id => [1, 13].includes(id)) : this.props.selectedCandidates;
        post("send-election-vote", { hashkey, votes }).then(res => {
            if (res === "ok") {
                this.props.setState({ selectedCandidates: [] })
                post("attendee-info", { hashkey: this.props.attendeeInfo.hashkey }).then(
                    attendeeInfo => {
                        this.props.setState({ attendeeInfo });
                    });
            } else {
                alert("Παρουσιάστηκε κάποιο πρόβλημα. Προσπαθήστε ξανά.");
            }
        });
    }

    toggleVote(id) {
        const selectedCandidates = this.props.selectedCandidates.includes(id) ?
            this.props.selectedCandidates.filter(c => c !== id) :
            [...this.props.selectedCandidates, id];
        this.props.setState({ selectedCandidates });
    }

    isSelected(id) {
        if ([1, 13].includes(id))
            return (this.props.selectedCandidates.includes(id));

        if (this.props.selectedCandidates.includes(1)) return false;
        if (this.props.selectedCandidates.includes(13)) return false;

        return this.props.selectedCandidates.includes(id)
    }

    isDisabled(id) {
        if ([1, 13].includes(id)) return false;
        if (this.props.selectedCandidates.includes(1)) return true;
        if (this.props.selectedCandidates.includes(13)) return true;
        return false;
    }

    render() {
        const { attendeeInfo: { elections }, electionInfo } = this.props;
        if (elections)
            return (
                <>
                    <Dialog
                        open={elections && electionInfo}
                    >
                        <DialogTitle>ΗΛΕΚΤΡΟΝΙΚΗ ΨΗΦΟΦΟΡΙΑ</DialogTitle>
                        <DialogContent dividers>
                            <DialogContentText>
                                Ανοίγουν τώρα οι «ηλεκτρονικές κάλπες» για να ξεκινήσει η ψηφοφορία για την εκλογή του νέου Διοικητικού Συμβουλίου και της Ελεγκτικής Επιτροπής του Σωματείου «ΔΙΑΖΩΜΑ».
                                    <br /><br />Για την διευκόλυνση της διαδικασίας οι «ηλεκτρονικές κάλπες» θα παραμείνουν ανοιχτές έως τις 15:30 μ.μ.
<br /><br />Έχετε δικαίωμα μέχρι 9 σταυρών για το Διοικητικό Συμβούλιο και μέχρι 3 σταυρών για την Ελεγκτική Επιτροπή.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <Button color="primary"
                                        variant="contained"
                                        fullWidth
                                        onClick={() => this.props.setState({ electionInfo: false })}>
                                        ΕΝΑΡΞΗ
                          </Button>
                                </Grid>

                            </Grid>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={elections && !electionInfo}
                    >
                        <DialogTitle>{elections.name} {elections.surname}</DialogTitle>
                        <DialogContent dividers>
                            <DialogContentText component="div">
                                {elections.board === 1 &&
                                    <p style={{textAlign:"center"}}>ΥΠΟΨΗΦΙΟΙ ΔΙΟΙΚΗΤΙΚΟΥ ΣΥΜΒΟΥΛΙΟΥ</p>
                                }
                                {elections.board === 2 &&
                                    <p style={{textAlign:"center"}}>ΥΠΟΨΗΦΙΟΙ ΕΛΕΓΚΤΙΚΗΣ ΕΠΙΤΡΟΠΗΣ</p>
                                }
                                <List>
                                    {this.props.candidatesList
                                        .filter(l => l.cnd_type === elections.board)
                                        .map((h, idx) => (
                                            <React.Fragment key={idx}>
                                                <ListItem button onClick={() => this.toggleVote(h.id)}
                                                    selected={this.isSelected(h.id)}
                                                    disabled={this.isDisabled(h.id)}
                                                >
                                                    <ListItemIcon>
                                                        {
                                                            this.isSelected(h.id) ?
                                                                <AddBoxIcon color="primary" />
                                                                :
                                                                <CheckBoxOutlineBlankIcon />
                                                        }
                                                    </ListItemIcon>
                                                    <ListItemText primary={`${h.name}`} />
                                                </ListItem>
                                                <Divider component="li" />
                                            </React.Fragment>
                                        ))}
                                </List>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    {(this.props.selectedCandidates.length > elections.limit && !this.isDisabled(2)) ?
                                        <p style={{ color: "red", fontSize: "0.8em", textAlign: "center" }}>Έχετε επιλέξει περισσότερους από {elections.limit} σταυρούς.</p>
                                        :
                                        <Button color="primary"
                                            variant="contained"
                                            fullWidth
                                            disabled={this.props.selectedCandidates.length === 0}
                                            onClick={() => this.setVote()}>
                                            ΥΠΟΒΟΛΗ
                          </Button>
                                    }
                                </Grid>

                            </Grid>
                        </DialogActions>
                    </Dialog>
                </>
            );
        return null;
    }

}

Elections.defaultProps = {
    attendeeInfo: {},
    candidatesList: [],
    selectedCandidates: [],
    electionInfo: true
};

const mapStateToProps = state => {
    return Object.keys(Elections.defaultProps).reduce(
        (obj, key) => ({ ...obj, [key]: state[key] }),
        {}
    );
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Elections);
