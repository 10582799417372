import React from "react";
import { connect } from "react-redux";

import { Typography } from "@material-ui/core";
import { setState } from "../store/actions";
import { post } from "../lib/api";
import Sponsors from "./Sponsors";

class Finished extends React.Component {


    render() {
        const { attendeeInfo } = this.props;
        return (
            <div style={{
                lineHeight: "160%",
                fontSize: "16px",
                maxWidth: "900px",
                margin: "auto",
            }}>
                <p style={{ textAlign: "right", fontSize: "0.8em" }}>
                    <span style={{ display: "inline-block", textAlign: "left", lineHeight:"1.5em" }}>
                        Συνδεθήκατε ως: <br /><b>{attendeeInfo.name} {attendeeInfo.surname}</b>
                    </span></p>
                <img src="/files/img/banner.png" style={{ width: "100%", marginBottom: "20px" }} />
                <p style={{ textAlign: "center" }}>Οι διαδικασίες της ΙZ΄ Γενικής Συνέλευσης του Σωματείου «ΔΙΑΖΩΜΑ» ολοκληρώθηκαν.</p>
                <p style={{ textAlign: "center" }}>Σας ευχαριστούμε θερμά για τη συμμετοχή σας και ανανεώνουμε το ραντεβού μας για του χρόνου.</p>
                <p style={{ textAlign: "center" }}>Τα αποτελέσματα των εκλογών θα αναρτηθούν στην ιστοσελίδα του Σωματείου «ΔΙΑΖΩΜΑ» (<a href="https://diazoma.gr">diazoma.gr</a>).</p>
                {/* <p style={{ textAlign: "center" }}>Τα αποτελέσματα των εκλογών έχουν αναρτηθεί στην ιστοσελίδα του Σωματείου «ΔΙΑΖΩΜΑ» (<a href="https://diazoma.gr">diazoma.gr</a>).</p> */}
                <Sponsors style={{ marginTop: "50px" }} />
            </div>
        );
    }
}

Finished.defaultProps = {
    sponsorImg: [],
    sponsorImgStart: 0,
    attendeeInfo: {}
};

const mapStateToProps = state => {
    return Object.keys(Finished.defaultProps).reduce(
        (obj, key) => ({ ...obj, [key]: state[key] }),
        {}
    );
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Finished);
