import React from "react";
import { connect } from "react-redux";

import { setState } from "../store/actions";
import {
  Hidden,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider
} from "@material-ui/core";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import SettingsIcon from "@material-ui/icons/Settings";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import WebIcon from "@material-ui/icons/Web";
import PeopleIcon from "@material-ui/icons/People";
import FaceIcon from "@material-ui/icons/Face";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import PollIcon from "@material-ui/icons/Poll";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import ArtTrackIcon from '@material-ui/icons/ArtTrack';

class AdminDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {}

  componentDidMount() {}

  render() {
    const { page } = this.props;
    const drawer = (
      <div style={{ width: "250px" }}>
        <h1 style={{ textAlign: "center" }}>
          <img src="/img/onezero.svg" style={{width:"80%"}}/>
        </h1>
        <Divider />
        <List>
          {[
            ["Attendees", <PeopleIcon />, "attendees"],
            ["Candidates", <PeopleIcon />, "candidates"],
            ["Questions", <ContactSupportIcon />, "questions"],
            ["Hand Voting", <FormatListBulletedIcon />, "handvoting"],
            ["Live Console", <SubscriptionsIcon />, "liveconsole"]
          ].map((text, index) => (
            <ListItem
              button
              selected={text[2] === page}
              component="a"
              key={text[2]}
              href={`/ozadmin/${text[2]}`}
            >
              <ListItemIcon>{text[1]}</ListItemIcon>
              <ListItemText primary={text[0]} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {[["Send e-mail", <MailIcon />, "email"]].map((text, index) => (
            <ListItem
              button
              selected={text[2] === page}
              component="a"
              key={text[2]}
              href={`/ozadmin/${text[2]}`}
            >
              <ListItemIcon>{text[1]}</ListItemIcon>
              <ListItemText primary={text[0]} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {[
            ["Setup", <SettingsIcon />, "setup"],
            ["Modules", <ViewModuleIcon />, "modules"],
            ["Pages", <WebIcon />, "pages"],
            ["Administrators", <FaceIcon />, "admins"]
          ].map((text, index) => (
            <ListItem
              button
              selected={text[2] === page}
              component="a"
              key={text[2]}
              href={`/ozadmin/${text[2]}`}
            >
              <ListItemIcon>{text[1]}</ListItemIcon>
              <ListItemText primary={text[0]} />
            </ListItem>
          ))}
        </List>
      </div>
    );

    return (
      <nav style={{ width: "250px" }}>
        <Drawer variant="permanent" anchor="left" open>
          {drawer}
        </Drawer>
        {/* <Hidden xsDown implementation="css">
          <Drawer variant="permanent" open>
            {drawer}
          </Drawer>
        </Hidden> */}
      </nav>
    );
  }
}
AdminDrawer.defaultProps = {
  mobileOpenMenu: false
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDrawer);
