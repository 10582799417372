import React from "react";
import { connect } from "react-redux";

import { setState } from "../store/actions";
import { loadData } from "../lib/api";
import DataTable from "./DataTable";
class Banners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {}

  componentDidMount() {
    loadData("data/banners", "bannersList");
  }

  getColumns() {
    return [
      { id: "name", label: "Descrption", align: "left", minWidth: "100px" },
      { id: "filename", label: "File Name", align: "left", minWidth: "100px" }
    ];
  }

  getCustomField(row, column) {
    return null;
  }

  render() {
    const columns = this.getColumns();
    const rows = [];

    return (
      <DataTable
        title="Banners"
        singular="Banner"
        columns={columns}
        list={this.props.bannersList}
        getCustomField={(row, column) => this.getCustomField(row, column)}
        listName="bannersList"
        crud="banners"
        searchFields={["name", "filename"]} 
        deletable
      />
    );
  }
}
Banners.defaultProps = {
  bannersList: []
};

const mapStateToProps = state => {
  return {
    bannersList: state.bannersList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Banners);
