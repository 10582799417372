import React from "react";
import { connect } from "react-redux";

import { Grid, CssBaseline, Button, Container, Box } from "@material-ui/core";
import { setState } from "../store/actions";
import { loadData } from "../lib/api";
import { PieChart, Pie, Label, Cell } from "recharts";

class PollResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
  }

  componentDidMount() {
    document.body.onkeydown = e => this.pressKey(e.keyCode);
    loadData("data/play", "editRow", 21);
    this.initWebsocket();
    setInterval(() => this.checkWebSocket(), 2000);

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.editRow !== this.props.editRow && this.props.editRow.chart_voting_question === "Διαπίστωση Απαρτίας")
      loadData("data/attendees", "attendeesList");
  }

  checkWebSocket() {
    if (this.ws && this.ws.readyState !== this.ws.OPEN) {
      this.initWebsocket();
    }
    if (!this.ws) this.initWebsocket();
  }


  initWebsocket() {
    const path = `${window.location.href
      .replace("http", "ws")
      .replace(window.location.pathname, "")}/ws/`;
    this.ws = new WebSocket(path);
    const parentObj = this;

    // if (this.props.attendeeInfo)
    //   loadData("courses", "courseInfo", this.props.attendeeInfo.course);

    // ws.onopen = function () {
    //   ws.send(`setUser:${user}`);
    // };
    console.log("open ws!!!");
    this.ws.onmessage = function (msg) {
      console.log(msg.data);
      if (msg.data.startsWith("refreshPlay:")) {
        loadData("data/play", "editRow", 21);
      }
    };
  }

  pressKey(keyCode) {
    console.log(keyCode);
    if (keyCode === 13) {
      loadData("data/play", "editRow", 21);
    }
  }

  onPieEnter(data, index) {
    this.setState({
      activeIndex: index
    });
  }

  render() {
    const { editRow } = this.props;
    const data = editRow.chart_voting_results
      ? editRow.chart_voting_results.map(r => ({
        idx: r[0],
        name: r[1],
        value: r[2]
      }))
      : [];

    const colors = ["#008800", "#880000"];

    let is_prescnt = false;

    if (this.props.editRow.chart_voting_question === "Διαπίστωση Απαρτίας") {
      is_prescnt = true;
    }

    let resStatus = 0;
    if(this.props.editRow.play_voting)resStatus = 1;
    if(this.props.editRow.chart_voting)resStatus = 2;

    const cnt_members = 442; // is_prescnt && this.props.attendeesList.filter(a => a.is_member).length;
    const cnt_connected_members = is_prescnt && this.props.attendeesList.filter(a => a.is_member && a.connected).length;
    const cnt_represented = 86; //is_prescnt && this.props.attendeesList.filter(a => a.connected && a.auth_member).length;
    const cnt_presents = is_prescnt && cnt_connected_members + cnt_represented;
    const cnt_perc = is_prescnt && Math.round(cnt_presents / cnt_members * 1000) / 10

    return (
      <Container
        style={{ color: "black", marginTop: "1vh", textAlign: "center" }}
      >
        <h2 style={{ textAlign: "center", margin: "3vw 10vw 0 10vw", transition: "transform 1s", transform: resStatus === 1 ? "translateY(30vh) scale(1.2)" : "none"}}>
          {editRow.play_voting_question}
          {editRow.chart_voting_question}
        </h2>
        {resStatus > 1 && is_prescnt && this.props.attendeesList.length > 0 && (
          <h1 style={{ lineHeight: "1.5em", marginTop: "10vh", fontSize: "3em" }}>
            {`Μέλη: ${cnt_members}`}<br />
            {`Παρόντες: ${cnt_connected_members}`}<br />
            {`Εξουσιοδοτήσεις: ${cnt_represented}`}<br />
            {`Σύνολο: ${cnt_presents} (${cnt_perc}%)`}<br />
          </h1>
        )}
        {resStatus > 1 && !is_prescnt && (
          <PieChart width={1000} height={500} style={{ margin: "auto" }}>
            <Pie
              isAnimationActive={resStatus === 2}
              // activeIndex={this.state.activeIndex}
              // activeShape={renderActiveShape}
              data={data}
              cx={500}
              cy={250}
              outerRadius={200}
              fill="#ffff00"
              dataKey="value"
              label={lbl => {
                return (
                  <text
                    textAnchor={lbl.textAnchor}
                    x={lbl.x}
                    y={lbl.y}
                    fill="black"
					font-size="1.8em"
                  >
                    {`${lbl.name} (${Math.round(lbl.percent * 100)}%)`}
                  </text>
                );
              }}
              labelLine={false}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    resStatus === 3 &&
                      entry.idx === editRow.chart_voting_correct_answer
                      ? "#f80"
                      : colors[index % colors.length]
                  }
                />
              ))}
            </Pie>
          </PieChart>
        )}
      </Container>
    );
  }
}

PollResults.defaultProps = {
  editRow: {},
  attendeesList: []
};

const mapStateToProps = state => {
  return Object.keys(PollResults.defaultProps).reduce(
    (obj, key) => ({ ...obj, [key]: state[key] }),
    {}
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PollResults);
