import React from "react";
import { connect } from "react-redux";

import { setState } from "../store/actions";
import AdminDrawer from "./AdminDrawer";
import Speakers from "./HandVoting";
import Program from "./Program";
import Companies from "./Companies";
import Attendees from "./Attendees";
import Candidates from "./Candidates";
import Questions from "./Questions";
import LiveConsole from "./LiveConsole";
import Banners from "./Banners";
import HandVoting from "./HandVoting";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {}

  componentDidMount() {}

  getPageContent(page) {
    if (page === "speakers") return <Speakers />;
    if (page === "program") return <Program />;
    if (page === "companies") return <Companies />;
    if (page === "attendees") return <Attendees />;
    if (page === "handvoting") return <HandVoting />;
    if (page === "questions") return <Questions />;
    if (page === "liveconsole") return <LiveConsole />;
    if (page === "candidates") return <Candidates />;
    
    return (
      <>
        <div style={{ flexGrow: 1 }}></div>
        <div style={{ textAlign: "center" }}><img src="/files/img/logo.png" style={{ width: "90%", maxWidth: "500px" }} /></div>
        <div style={{ flexGrow: 1 }}></div>
      </>
    );
  }

  render() {
    const { page } = this.props.match.params;
    return (
      <div className="apg-parent-div" style={{ display: "flex" }}>
        <AdminDrawer page={page} />
        <main style={{ width: `calc(100% - 250px)` }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh"
            }}
          >
            {this.getPageContent(page)}
          </div>
        </main>
      </div>
    );
  }
}
Admin.defaultProps = {};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
