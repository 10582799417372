import React from "react";
import { connect } from "react-redux";

import { setState } from "../store/actions";
import DataTable from "./DataTable";

import { loadData } from "../lib/api";

class Program extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {}

  componentDidMount() {
    loadData("data/program", "programList");
  }

  getColumns() {
    return [
      { id: "ord", label: "Order", align: "left", minWidth: "100px" },
      { id: "node_type", label: "Type", align: "left", minWidth: "100px", type:"list", 
        list:[{id:"day", descr:"Day"},{id:"title", descr:"Header"},{id:"speak", descr:"Detail"}]},
      { id: "txt1", label: "Text1", align: "left", minWidth: "100px" },
      { id: "txt2", label: "Text2", align: "left", minWidth: "100px" },
      { id: "txt3", label: "Text3", align: "left", minWidth: "100px" }
    ];
  }

  getCustomField(row, column){
    return null
  }

  render() {
    const columns = this.getColumns();

    return (
      <DataTable
        title= "Program"
        singular="Program Row"
        columns = {columns}
        list = {this.props.programList}
        getCustomField = {(row, column) => this.getCustomField(row, column)}
        listName= "programList"
        crud="program"
        searchFields={["name","txt1","txt2","txt3"]}
        deletable
        />
    );
  }
}

Program.defaultProps = {
  programList: []
};

const mapStateToProps = state => {
  return {
    programList: state.programList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Program);
