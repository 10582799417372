import React from "react";
import { connect } from "react-redux";

import { Grid, CssBaseline, Button, DialogContent, DialogContentText, Dialog, DialogTitle, DialogActions, Typography } from "@material-ui/core";
import { setState } from "../store/actions";
import { post } from "../lib/api";

class HandVoting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps, prevState) { }

    setPollAnswer(answer) {
        const { handvoting: { id, attendee: { hashkey } } } = this.props.attendeeInfo;
        post("send-handvoting-answer", { hashkey, id, answer }).then(res => {
            if (res === "ok") {
                post("attendee-info", { hashkey: this.props.attendeeInfo.hashkey }).then(
                    attendeeInfo => {
                        this.props.setState({ attendeeInfo });
                    });
            } else {
                alert("Παρουσιάστηκε κάποιο πρόβλημα. Προσπαθήστε ξανά.");
            }
        });
    }

    render() {
        const { attendeeInfo: { handvoting } } = this.props;
        if (this.props.attendeeInfo && this.props.attendeeInfo.handvoting)
            return (
                <Dialog
                    open={this.props.attendeeInfo && this.props.attendeeInfo.handvoting}
                >
                    <DialogTitle>{handvoting.attendee.name} {handvoting.attendee.surname}</DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText style={{color:"black"}}>
                            {handvoting.question}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={1} style={{margin:"15px"}}>
                            <Grid item xs={12}>
                                <Typography>
                                Παρακαλώ επιλέξτε την ένδειξη ΝΑΙ ή ΟΧΙ για να εκφράσετε ανάλογα τη σύμφωνη ή μη γνώμη σας.
                                </Typography>
                            </Grid>
                            <Grid item xs>
                            <Button style={{ backgroundColor: "#800", color:"white", fontWeight:"bold", fontSize:"1.2em" }}
                                    fullWidth
                                    onClick={() => this.setPollAnswer(0)}>
                                    ΟΧΙ
                          </Button>
                            </Grid>
                            <Grid item xs>
                                <Button style={{ backgroundColor: "#080", color:"white", fontWeight:"bold", fontSize:"1.2em" }}
                                    fullWidth
                                    onClick={() => this.setPollAnswer(1)}>
                                    ΝΑΙ
                          </Button>
                            </Grid>

                        </Grid>
                    </DialogActions>
                </Dialog>
            );
        return null;
    }

}

HandVoting.defaultProps = {
    attendeeInfo: {},
};

const mapStateToProps = state => {
    return Object.keys(HandVoting.defaultProps).reduce(
        (obj, key) => ({ ...obj, [key]: state[key] }),
        {}
    );
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HandVoting);
