import React from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Switch,
  FormControlLabel,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Button,
  TextField,
  Grid,
  Box
} from "@material-ui/core";
import { setState } from "../store/actions";
import { crud, loadData } from "../lib/api";
class EditModal extends React.Component {
  editField(key, value) {
    this.props.setState({ editRow: { ...this.props.editRow, [key]: value } });
  }

  getColName(col) {
    return col.name;
  }

  doSave(del) {
    if (this.saving) return;
    if (del) {
      const confirmDel = window.confirm(
        `Delete the selected ${this.props.singular.toLowerCase()}?`
      );
      if (!confirmDel) return;
    }
    this.saving = true;
    let id = this.props.editRow.id;
    let data = this.props.editRow;
    if (this.props.extrafields) data = { ...data, ...this.props.extrafields };
    if (del) data = null;
    crud(this.props.crud, id, data)
      .then(json => {
        this.props.setState({ showEditModal: false });
        loadData(`data/${this.props.crud}`, this.props.listName).then(() => {
          this.saving = false;
        });
      })
      .catch(res => {
        this.saving = false;
      });
  }

  getField(field, idx) {
    const strVal = this.props.editRow[field.id];


    if (field.type === "checkbox") {
      return (
        <FormControlLabel
          label={field.label}
          control={
            <Switch
              color="primary"
              checked={strVal}
              onChange={event =>
                this.editField(field.id, event.target.checked)
              }
            />
          }
        />
      );
    }


    if (field.type === "list") {
      return (
        <FormControl fullWidth>
          <InputLabel>{field.label}</InputLabel>
          <Select
            value={strVal}
            onChange={event => this.editField(field.id, event.target.value)}
          >
            {field.list.map((row, idx) => (
              <MenuItem key={`listitem_${idx}`} value={row.id}>
                {row.descr}
              </MenuItem>
            ))}
            {!field.required && <MenuItem value={null}>&nbsp;</MenuItem>}
          </Select>
        </FormControl>
      );
    }

    return (
      <TextField
        autoComplete="off"
        autoFocus={idx === 0}
        value={strVal}
        label={field.label}
        onChange={event => this.editField(field.id, event.target.value)}
        fullWidth
      />
    );
  }

  render() {
    const title = "ok";
    return (
      <Dialog open={this.props.showEditModal}>
        <DialogTitle>{this.props.editTitle}</DialogTitle>
        <DialogContent style={{ minWidth: "300px" }}>
          <Grid container spacing={2}>
            {this.props.editColumns
              .filter(c => !c.readonly)
              .map((field, idx) => (
                <Grid
                  item
                  key={`editfield${idx}`}
                  xs={field.controlWidth || 12}
                >
                  {this.getField(field, idx)}
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "25px" }}>
          {this.props.editRow.id > 0 && this.props.deletable && (
            <Button
              onClick={() => this.doSave(true)}
              color="secondary"
              variant="contained"
            >
              Delete {this.props.singular}
            </Button>
          )}
          <Box style={{ flexGrow: 1 }}></Box>
          <Button
            onClick={() => this.doSave(false)}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={() => this.props.setState({ showEditModal: false })}
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

EditModal.defaultProps = {
  showEditModal: false,
  editTitle: "",
  singular: "",
  editRow: {},
  editColumns: []
};

const mapStateToProps = state => {
  return {
    showEditModal: state.showEditModal,
    editTitle: state.editTitle,
    editRow: state.editRow,
    editColumns: state.editColumns,
    saving: state.saving
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditModal);
