import React from "react";
import { connect } from "react-redux";

import { setState } from "../store/actions";
import DataTable from "./DataTable";
import { IconButton, Box } from "@material-ui/core";
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import { loadData, crud, post } from "../lib/api";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ClearIcon from '@material-ui/icons/Clear';
import UndoIcon from '@material-ui/icons/Undo';
import MailIcon from '@material-ui/icons/Mail';

class Attendees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) { }

  componentDidMount() {
    loadData("data/attendees", "attendeesList");
  }

  getColumns() {
    return [
      {
        id: "is_member",
        label: "Member",
        align: "left",
        minWidth: "10px",
        type: "checkbox"
      },
      { id: "name", label: "Name", align: "left", minWidth: "100px" },
      { id: "surname", label: "Surname", align: "left", minWidth: "100px" },
      {
        id: "connected",
        label: "Connected",
        align: "left",
        minWidth: "10px",
        type: "checkbox", readonly: true
      },
      { id: "do_represent", label: "", type: "custom", readonly: true },
      { id: "represent", label: "Represented Member", align: "left", minWidth: "100px", readonly: true },
      { id: "email", label: "eMail", align: "left", minWidth: "100px" },
      { id: "comments", label: "Comments", align: "left", minWidth: "100px" },
      { id: "phone", label: "Phone", align: "left", minWidth: "100px" },
      { id: "live", label: "", type: "custom", readonly: true },
      { id: "email", label: "", type: "custom", readonly: true },
      {
        id: "is_demo",
        label: "Demo",
        align: "left",
        minWidth: "10px",
        type: "checkbox"
      },
    ];
  }

  clearRepresents(row) {
    crud("attendees", row.id, { ...row, auth_member: null })
      .then(json => {
        loadData("data/attendees", "attendeesList");
        this.props.setState({ represented: 0 });
      })
      .catch(res => {
        this.props.setState({ represented: 0 });
      });
  }

  callRepresents(row) {
    if (!this.props.represented) {
      this.props.setState({ represented: row.id });
      return;
    }
    if (this.props.represented === row.id) {
      this.props.setState({ represented: 0 });
      return;
    }

    crud("attendees", row.id, { ...row, auth_member: this.props.represented })
      .then(json => {
        loadData("data/attendees", "attendeesList");
        this.props.setState({ represented: 0 });
      })
      .catch(res => {
        this.props.setState({ represented: 0 });
      });
  }

  getCustomField(row, column) {
    if (column.id === "do_represent") {
      if (!row.is_member)return null;
      
      if (row.auth_member) {
        return (
          <IconButton
            onClick={() => this.clearRepresents(row)}>
            <ClearIcon />
          </IconButton>
        );
      }
      const repr = this.props.attendeesList.find(a => a.auth_member === row.id);

      if(repr){
        return (
          <IconButton
            onClick={() => alert(`Represented by ${repr.name} ${repr.surname}`)}>
            <UndoIcon />
          </IconButton>
        );
      }
      

      return (
        <IconButton
          color={this.props.represented ? (this.props.represented === row.id ? "secondary" : "primary") : "default"}
          onClick={() => this.callRepresents(row)}>
          <PeopleAltIcon />
        </IconButton>
      );

    }

    if (column.id === "email")
      return (
        <IconButton
          component="a"
          href={`mailto:${row.email}?subject=ΔΙΑΖΩΜΑ - ΙΖ' Γενική Συνέλευση&body=https://diazoma.onezero.gr/live/${row.hashkey}`}
          target="_blank">
          <MailIcon />
        </IconButton>
      );

    if (column.id === "live")
      return (
        <IconButton
          component="a"
          href={`/live/${row.hashkey}`}
          target="_blank">
          <ScreenShareIcon />
        </IconButton>
      );

    return null;
  }

  render() {
    const columns = this.getColumns();
    const cnt_members = this.props.attendeesList.filter(a => a.is_member).length;
    const cnt_connected = this.props.attendeesList.filter(a => a.connected).length;
    const cnt_connected_members = this.props.attendeesList.filter(a => a.is_member && a.connected).length;
    const cnt_represented = this.props.attendeesList.filter(a => a.connected && a.auth_member).length;
    const cnt_presents = cnt_connected_members + cnt_represented;
    const cnt_perc = Math.round(cnt_presents / cnt_members * 1000) / 10

    return (
      <DataTable
        title="Attendees"
        singular="Attendee"
        columns={columns}
        list={this.props.attendeesList}
        getCustomField={(row, column) => this.getCustomField(row, column)}
        listName="attendeesList"
        crud="attendees"
        searchFields={["name", "surname", "email", "company_title", "comments"]}
        deletable
        subBox={<Box textAlign="right" pr={5} pb={2} lineHeight="200%">
          Connected attendees: <b>{cnt_connected}</b>
          <br />Total Members:<b>{cnt_members}</b>. Connected Members: <b>{cnt_connected_members}</b>. Represented: <b>{cnt_represented}</b>.{" "}
          <br /><span style={{ marginLeft: "50px" }}>{cnt_connected_members} + {cnt_represented} = {cnt_presents} <b>({cnt_perc}%)</b></span>
        </Box>}
      />
    );
  }
}

Attendees.defaultProps = {
  attendeesList: [],
  searchText: "",
  represented: 0
};

const mapStateToProps = state => {
  return {
    attendeesList: state.attendeesList,
    searchText: state.searchText,
    represented: state.represented
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Attendees);
