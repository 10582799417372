import React from "react";
import { connect } from "react-redux";

import { setState } from "../store/actions";
import DataTable from "./DataTable";

import { loadData } from "../lib/api";

class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {}

  componentDidMount() {
    loadData("data/companies", "companiesList");
  }

  getColumns() {
    return [
      { id: "title", label: "Company Name", align: "left", minWidth: "200px" },
      { id: "reg_type", label: "Reg. Type", align: "left", minWidth: "100px", type:"list", list:[{id:1, descr:"on line"},{id:2, descr:"on site"}] },
      { id: "code", label: "Reg. Code", align: "left", minWidth: "100px" }
    ];
  }

  getCustomField(row, column){
    return null
  }

  render() {
    const columns = this.getColumns();

    return (
      <DataTable
        title= "Companies"
        singular="Company"
        columns = {columns}
        list = {this.props.companiesList}
        getCustomField = {(row, column) => this.getCustomField(row, column)}
        crud="companies"
        listName="companiesList"
        searchFields={["title","code"]}
        />
    );
  }
}

Companies.defaultProps = {
  companiesList: []
};

const mapStateToProps = state => {
  return {
    companiesList: state.companiesList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
