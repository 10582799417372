import React from "react";
import { connect } from "react-redux";

import { setState } from "../store/actions";
import DataTable from "./DataTable";
import { IconButton } from "@material-ui/core";
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import { loadData, crud, post } from "../lib/api";

class Candidates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    loadData("data/candidates", "candidatesList");
  }

  getColumns() {
    return [
      { id: "ord", label: "Order", align: "left", minWidth: "100px" },
      { id: "cnd_type", label: "Board", align: "left", minWidth: "100px",  type:"list", list:[{id:1, descr:"Δ.Σ."},{id:2, descr:"ΕΛ.ΕΠ."}] },
      { id: "name", label: "Name", align: "left", minWidth: "100px" },
      { id: "surname", label: "Surname", align: "left", minWidth: "100px" },
      { id: "bio", label: "Title", align: "left", minWidth: "100px" },            
      { id: "email", label: "eMail", align: "left", minWidth: "100px" },
      { id: "votes", label: "Votes", align: "left", minWidth: "100px", readonly:true }
    ];
  }

  getCustomField(row, column) {
    if (column.id === "live")
      return (
        <IconButton
        component="a"
        href={`/live/${row.hashkey}`}
        target="_blank">                      
        <ScreenShareIcon />
      </IconButton>
);

    return null;
  }

  render() {
    const columns = this.getColumns();

    return (
      <DataTable
        title="Candidates"
        singular="Candidate"
        columns={columns}
        list={this.props.candidatesList}
        getCustomField={(row, column) => this.getCustomField(row, column)}
        listName="candidatesList"
        crud="candidates"
        searchFields={["name", "surname", "email"]}
        deletable
      />
    );
  }
}

Candidates.defaultProps = {
  candidatesList: [],
  searchText: ""
};

const mapStateToProps = state => {
  return Object.keys(Candidates.defaultProps).reduce(
      (obj, key) => ({ ...obj, [key]: state[key] }),
      {}
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Candidates);
