import React from "react";
import { connect } from "react-redux";

import { Container } from "@material-ui/core";
import { setState } from "../store/actions";
import { post } from "../lib/api";
import Info from "./Info";
import Live from "./Live";
import Finished from "./Finished";


class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.videoRef = React.createRef();
        this.hashkey = this.props.match.params.hashkey || "-";
        this.loadInfo(true);
        window.addEventListener('focus', (event) => {
            this.loadInfo(false);
        }, true);
    }

    loadInfo(init) {
        post("attendee-info", { hashkey: this.hashkey }).then(
            attendeeInfo => {
                this.props.setState({ attendeeInfo });
            });
    }

    updateQuestion(msg) {
        const id = Number(msg[1]);
        const likes = Number(msg[2]);
        const attendee = Number(msg[3]);
        const quest = this.props.questionsList.filter(q => q.id === id)
        const newState = {}
        if (quest.length > 0) {
            newState.questionsList = this.props.questionsList.map(q => q.id === id ? { ...q, likes } : q);
        }
        else {
            if (likes >= 0 && !this.props.newQuestions.includes(id))
                newState.newQuestions = [...this.props.newQuestions, id];
            if (likes < 0 && this.props.newQuestions.includes(id))
                newState.newQuestions = this.props.newQuestions.filter(v => v !== id);
        }
        this.props.setState(newState);
    }

    componentDidMount() {
        this.initWebsocket();
        setInterval(() => this.checkWebSocket(), 10000);
    }

    componentDidUpdate(prevProps, prevState) { }

    checkWebSocket() {
        if (this.ws && this.ws.readyState !== this.ws.OPEN) {
            console.log(this.ws.readyState);
            this.initWebsocket();
        }
        if (!this.ws) this.initWebsocket();
    }

    initWebsocket() {
        const path = `${window.location.href
            .replace("http", "ws")
            .replace(window.location.pathname, "")}/ws/`;
        this.ws = new WebSocket(path);
        const parentObj = this;

        // if (this.props.attendeeInfo)
        //   loadData("courses", "courseInfo", this.props.attendeeInfo.course);

        // ws.onopen = function () {
        //   ws.send(`setUser:${user}`);
        // };
        console.log("open ws!!!");
        this.ws.onmessage = function (msg) {
            console.log(msg.data);
            if (msg.data.startsWith("refreshPlay:")) {
                parentObj.loadInfo(false);
            }
            if (msg.data.startsWith("updQuestion:")) {
                parentObj.updateQuestion(msg.data.split(":"));
            }

        };
    }

    renderPage() {
        const { attendeeInfo: { play_status, elections } } = this.props;
        if (play_status === 0) return <Info />;
        if (play_status === 4) return <Finished />;
        // if (play_status === 3 && !elections) return <Finished />;
        return <Live />;
    }

    render() {
        const { attendeeInfo: { play_status, elections } } = this.props;
        return (
            <Container
                style={{
                    display: "flex",
                    marginTop: "20px",
                    flexDirection: "column",
                    marginBottom: "100px"
                }}
            >
                {this.renderPage()}
            </Container>
        );
    }
}

Home.defaultProps = {
    attendeeInfo: {},
    newQuestions: [],
    questionsList: [],
};

const mapStateToProps = state => {
    return Object.keys(Home.defaultProps).reduce(
        (obj, key) => ({ ...obj, [key]: state[key] }),
        {}
    );
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
