import React from "react";
import { connect } from "react-redux";

import { Grid, CssBaseline, Button, Container, Box } from "@material-ui/core";
import { setState } from "../store/actions";
import Questions from "./Questions";
import HandVoting from "./HandVoting";
import Elections from "./Elections";
import { loadData } from "../lib/api";
import Sponsors from "./Sponsors";

class Live extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps, prevState) { }

    componentDidMount() {
        loadData("data/candidates", "candidatesList");
    }

    getVideoLink() {
        const { attendeeInfo } = this.props;

        const video_link = attendeeInfo[`video_link`];
        const video_provider = attendeeInfo[`video_provider`];

        if (video_provider === "Vimeo")
            return `https://vimeo.com/event/${video_link}/embed?autoplay=1`;
        if (video_provider === "Youtube")
            return `https://www.youtube.com/embed/${video_link}?autoplay=1`;
        return "";
    }


    render() {
        const { attendeeInfo } = this.props;
        return (
            <Grid container spacing={1} alignItems="stretch">
                <Grid item xs={12}>
                    <img src="/files/img/banner.png" style={{ width: "100%" }} />
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                    <div
                        className="apg-livevideo"
                        style={{
                            width: "100%",
                            background: "transparent",
                            paddingTop: "56.25%",
                            position: "relative",
                            overflow: "hidden",
                            marginTop: "10px"
                        }}
                    >
                        <iframe
                            src={this.getVideoLink()}
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                top: "0px",
                                left: "0px"
                            }}
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            ref={this.videoRef}
                            allowFullScreen
                        ></iframe>
                    </div>
                    <Sponsors smallSize />
                </Grid>
                <Questions />
                <HandVoting />
                <Elections />
            </Grid>
        );
    }
}

Live.defaultProps = {
    attendeeInfo: {}
};

const mapStateToProps = state => {
    return Object.keys(Live.defaultProps).reduce(
        (obj, key) => ({ ...obj, [key]: state[key] }),
        {}
    );
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Live);
