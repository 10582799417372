import React from "react";
import { connect } from "react-redux";

import { setState } from "../store/actions";
import { loadData, crud } from "../lib/api";
import {
  Paper,
  IconButton,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip
} from "@material-ui/core";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import ClearIcon from "@material-ui/icons/Clear";
import PublicIcon from '@material-ui/icons/Public';
import PieChartIcon from "@material-ui/icons/PieChart";
import ScreenShareIcon from '@material-ui/icons/ScreenShare';

class LiveConsole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    loadData("data/play", "editRow", 21);
    loadData("data/handvoting", "handVotingList");
    loadData("data/banners", "bannersList");
    this.playStatuses = ["Registration", "Live", "Hand Voting", "Voting", "Finished"];
  }

  componentDidUpdate(prevProps, prevState) { }

  componentDidMount() {
    loadData("data/questions", "questionsList");
  }

  updatePlay(editRow) {
    crud("play/", editRow.id, editRow).then(res => {
      this.props.setState({ editRow: res });
    });
  }

  setPlayStatus(incr) {
    const status = this.props.editRow.status + incr;
    const editRow = { ...this.props.editRow, status };
    this.props.setState({ editRow });
    this.updatePlay(editRow);
  }

  setPoll(field, poll) {
    const editRow = { ...this.props.editRow };
    editRow.chart_voting = null;
    editRow.play_voting = null;
    if (field !== "clear") editRow[field] = poll;

    this.props.setState({ editRow });
    this.updatePlay(editRow);
  }

  setBanner(banner) {
    const editRow = { ...this.props.editRow, banner };
    this.props.setState({ editRow });
    this.updatePlay(editRow);
  }

  updateVideo() {
    const { video_link, video_provider } = this.props;
    const editRow = {
      ...this.props.editRow,
      video_link,
      video_provider
    };
    this.props.setState({ editRow });
    this.updatePlay(editRow);
  }

  getColumns() {
    return [
      {
        id: "ts",
        label: "ts",
        align: "left",
        minWidth: "10px",
        type: "datetime"
      },
      {
        id: "attendee",
        label: "Attendee",
        align: "left",
        minWidth: "100px",
        type: "custom",
        readonly: true
      },
      { id: "question", label: "Question", align: "left", minWidth: "100px" }
    ];
  }

  getCustomField(row, column) {
    if (column.id === "attendee")
      return `${row.attendee_name} ${row.attendee_surname}`;
    return null;
  }

  render() {
    const columns = this.getColumns();
    const rows = [];

    return (
      <Paper style={{ flexGrow: 1, margin: "20px" }}>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <h1 style={{ textAlign: "center" }}>Live Console</h1>
            <h2 style={{ textAlign: "center" }}>
              <IconButton
                disabled={this.props.editRow.status <= 0}
                onClick={() => this.setPlayStatus(-1)}
              >
                <SkipPreviousIcon fontSize="large" />
              </IconButton>
              <p style={{ width: "200px", display: "inline-block" }}>
                {this.playStatuses[this.props.editRow.status]}
              </p>
              <IconButton
                disabled={this.props.editRow.status >= this.playStatuses.length - 1}
                onClick={() => this.setPlayStatus(1)}
              >
                <SkipNextIcon fontSize="large" />
              </IconButton>
            </h2>
            <h3 style={{ textAlign: "center" }}>
              {this.props.editRow[`video_provider`]}:{" "}
              {this.props.editRow[`video_link`]}
            </h3>
            <h3 style={{ textAlign: "center", marginTop: "30px" }}>
              <FormControl variant="outlined">
                <InputLabel>Provider</InputLabel>
                <Select
                  style={{ width: "150px" }}
                  label={`Provider`}
                  onChange={event =>
                    this.props.setState({
                      [`video_provider`]: event.target.value
                    })
                  }
                  value={this.props[`video_provider`] || ""}
                >
                  <MenuItem key="Vimeo" value="Vimeo">
                    Vimeo
                  </MenuItem>
                  <MenuItem key="Youtube" value="Youtube">
                    Youtube
                  </MenuItem>
                  <MenuItem key="Dacast" value="Dacast">
                    Dacast
                  </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                style={{ width: "200px", marginBottom: "5px" }}
                variant="outlined"
                onChange={event =>
                  this.props.setState({
                    [`video_link`]: event.target.value
                  })
                }
                value={this.props[`video_link`] || ""}
                label={`${this.props[`video_provider`]} Id`}
              />
              <br />
              <Button
                style={{ width: "350px", marginBottom: "5px" }}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => this.updateVideo()}
              >
                Update
              </Button>
              <br />
              <Button
                style={{ width: "350px" }}
                variant="contained"
                href={`/live/apg!!test`}
                target="_blank"
              >
                Test
              </Button>
            </h3>
          </Grid>
          <Grid item xs={6}>
            <h1 style={{ textAlign: "center" }}>
              Polls
              <IconButton
                edge="end"
                style={{ float: "right" }}
                onClick={() => this.setPoll("clear", 0)}
                component="a"
                href={`/pollresults`}
                target="_blank">
                <ScreenShareIcon />
              </IconButton>
            </h1>
            <List>
              {this.props.handVotingList.map((p, idx) => (
                <ListItem
                  key={`row${idx}`}
                  selected={
                    this.props.editRow.chart_voting === p.id ||
                    this.props.editRow.play_voting === p.id
                  }
                >
                  <ListItemText style={{ marginRight: "60px" }} primary={p.question} secondary={`(YES:${p.yes}, NO:${p.no})`} />
                  <ListItemSecondaryAction>
                    {(this.props.editRow.chart_voting === p.id || this.props.editRow.play_voting === p.id) && (
                      <IconButton
                        edge="end"
                        onClick={() => this.setPoll("clear", p.id)}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <IconButton
                      edge="end"
                      color={this.props.editRow.play_voting === p.id ? "secondary" : "default"}
                      onClick={() => this.setPoll("play_voting", p.id)}
                    >
                      <PublicIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      color={this.props.editRow.chart_voting === p.id ? "secondary" : "default"}
                      onClick={() => this.setPoll("chart_voting", p.id)}
                    >
                      <PieChartIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
LiveConsole.defaultProps = {
  editRow: {},
  video_provider: "Vimeo",
  video_link: "",
  handVotingList: [],
  bannersList: []
};

const mapStateToProps = state => {
  return Object.keys(LiveConsole.defaultProps).reduce(
    (obj, key) => ({ ...obj, [key]: state[key] }),
    {}
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveConsole);
