import { setState } from "../store/actions";
import store from "../store";
import { format } from "date-fns";

export async function loadData(table, node, id) {
    let url = id ? `/api/${table}/${id}/` : `/api/${table}/`;
    fetch(url, {
        method: "GET"
    })
        .then(res => (res.ok ? res : Promise.reject(res)))
        .then(res => res.json())
        .then(data => {
            const state = { dataloading: false };
            if (node) state[node] = data;
            store.dispatch(setState(state));
            return Promise.resolve(data);
        })
        .catch(error => {
            console.log(error);
            store.dispatch(setState({ dataloading: false }));
            return Promise.reject(error);
        });
}

export async function crud(table, id, data) {
    // if data is null then delete row
    let body = JSON.stringify(data || {});
    let url = `/api/data/${table}/`;
    if (id) url += `${id}/`;
    const method = !data ? "DELETE" : id > 0 ? "PUT" : "POST";

    let headers = {
        "Content-Type": "application/json"
        //    Authorization: `Token ${localStorage.getItem("auth_token")}`
    };

    return fetch(url, { headers, body, method })
        .then(res => (res.ok ? res : Promise.reject(res.statusText)))
        .then(res => (res.status === 204 ? {} : res.json()))
        .then(json => {
            if (json.error) return Promise.reject(json.error);
            return Promise.resolve(json);
        })
        .catch(msg => {
            console.log(msg);
            return Promise.reject(msg);
        });
}

export async function post(endpoint, data) {
    // if data is null then delete row
    let body = JSON.stringify(data || {});
    let url = `/api/${endpoint}`;
    const method = "POST";

    let headers = {
        "Content-Type": "application/json"
        //    Authorization: `Token ${localStorage.getItem("auth_token")}`
    };
    return fetch(url, { headers, body, method })
        .then(res => (res.ok ? res : Promise.reject(res.statusText)))
        .then(res => (res.status === 204 ? {} : res.json()))
        .then(json => {
            if (json.error) return Promise.reject(json.error);
            return Promise.resolve(json);
        })
        .catch(msg => {
            console.log(msg);
            return Promise.reject(msg);
        });
}

export function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail)) {
        return true;
    }
    return false;
}

export function ValidateAFM(taxnum) {
    if (!taxnum) return false;
    if (taxnum.length !== 9) return false;
    if (isNaN(taxnum)) return false;
    return true;
}


export function formatDate(ts) {
    return ts ? format(new Date(ts * 1000), "dd/MM HH:mm") : null;
  }