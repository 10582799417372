import React from "react";
import { connect } from "react-redux";
import { post, loadData, formatDate } from "../lib/api";

import { Grid, Paper, Button, List, ListItem, TextField, Box, IconButton, ListItemIcon, ListItemText, Typography, Divider, ButtonGroup } from "@material-ui/core";
import { setState } from "../store/actions";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';

class Questions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.refreshList();
    }

    refreshList() {
        this.props.setState({ newQuestions: [] });
        const hashkey = this.props.attendeeInfo.hashkey;
        post("read-questions", { hashkey }).then(res => {
            this.props.setState({ questionsList: res, orderBy: "ts" });
        });
    }

    sendQuestion() {
        const hashkey = this.props.attendeeInfo.hashkey;
        const question = this.props.qna_question;
        this.props.setState({ savingQuestion: true });
        post("send-question", { hashkey, question }).then(res => {
            if (res === "ok") {
                this.props.setState({
                    savingQuestion: false,
                    qna_question: ""
                });
                this.refreshList();
            } else {
                alert(res);
                this.props.setState({
                    savingQuestion: false
                });
            }
        });
    }

    sendQuestionLike(question) {
        const hashkey = this.props.attendeeInfo.hashkey;
        const del = this.props.attendeeInfo.likedQuestions.includes(question)
        const likedQuestions = del ?
            this.props.attendeeInfo.likedQuestions.filter(id => id !== question) :
            [...this.props.attendeeInfo.likedQuestions, question]

        this.props.setState({ attendeeInfo: { ...this.props.attendeeInfo, likedQuestions } });
        post("send-question-like", { hashkey, question, del }).then(res => {
            if (res === "ok") {

            } else {
                alert(res);
            }
        });
    }

    toggleOrderBy(orderBy) {
        this.props.setState({ orderBy })
    }

    render() {
        const { attendeeInfo, savingQuestion, orderBy } = this.props;
        return (
            <Grid item xs={12} md={4} lg={3} container direction="column" >
                <Box style={{ height: "100%" }}>
                    <Grid container direction="column" style={{ height: "100%" }}>
                        <Grid item >
                            <h3 style={{ textAlign: "center" }}>{attendeeInfo.name} {attendeeInfo.surname}</h3>
                        </Grid>
                        <Grid item >
                            <TextField
                                fullWidth
                                multiline
                                onChange={event =>
                                    this.props.setState({
                                        qna_question: event.target.value
                                    })
                                }
                                disabled={savingQuestion}
                                value={this.props.qna_question || ""}
                                variant="outlined"
                                placeholder="Γράψτε την ερώτηση σας...."
                                rows={this.props.qna_question === "" ? 1 : 6}
                            />
                        </Grid>
                        <Grid item>
                            <Box m="10px 0px">
                                {this.props.qna_question !== "" && !savingQuestion ?
                                    <Grid container spacing={1} >
                                        <Grid item xs={6}>
                                            <Button
                                                onClick={() => this.props.setState({ qna_question: "" })}
                                                size="large"
                                                variant="contained"
                                                fullWidth
                                            >
                                                ΑΚΥΡΟ
        </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                onClick={() => this.sendQuestion()}
                                                size="large"
                                                variant="contained"
                                                fullWidth
                                                color="primary"
                                            >
                                                ΚΑΤΑΧΩΡΗΣΗ
        </Button>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid item xs={12}>
                                        <Grid item xs>
                                            {this.props.newQuestions.length > 0 ?
                                                <Button
                                                    onClick={() => this.refreshList()}
                                                    size="large"
                                                    variant="contained"
                                                    fullWidth
                                                    color="primary"
                                                >
                                                    {this.props.newQuestions.length === 1 ? "1 ΝΕΑ ΕΡΩΤΗΣΗ" : `${this.props.newQuestions.length} ΝΕΕΣ ΕΡΩΤΗΣΕΙΣ`}
                                                </Button>
                                                :
                                                <ButtonGroup fullWidth>
                                                    <Button
                                                        onClick={() => this.toggleOrderBy("ts")}
                                                        size="small"
                                                        variant="contained"
                                                        color= {this.props.orderBy === "ts" ? "primary" : "default"}
                                                    >
                                                        ΠΡΟΣΦΑΤΕΣ
                                                    </Button>
                                                    <Button
                                                        onClick={() => this.toggleOrderBy("likes")}
                                                        size="small"
                                                        variant="contained"
                                                        color= {this.props.orderBy === "likes" ? "primary" : "default"}
                                                    >
                                                        ΔΗΜΟΦΙΛΕΙΣ
                                                    </Button>
                                                </ButtonGroup>
                                            }
                                        </Grid>
                                    </Grid>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs style={{ position: "relative" }}>
                            <Paper style={{ position: "absolute", height: "100%", width: "100%", minHeight: "40vh", overflow: "auto" }}>
                                <List>
                                    {this.props.questionsList.filter(l => l.likes >= 0).sort((a, b) => b[this.props.orderBy] - a[this.props.orderBy])
                                        .map((h, idx) => (
                                            <React.Fragment key={idx}>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Button
                                                            edge="start"
                                                            onClick={() => this.sendQuestionLike(h.id)}
                                                        >
                                                            {attendeeInfo.likedQuestions.includes(h.id) ? <FavoriteIcon /> :
                                                                <FavoriteBorderIcon />}
                                                            <span>{h.likes}</span>
                                                        </Button>

                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={h.question}
                                                        secondary={
                                                            <>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    color="textPrimary"
                                                                >
                                                                    {`${h.attendee_name} ${h.attendee_surname}`}
                                                                </Typography>
                                                                <br />
                                                                {`${formatDate(h.ts)} `}
                                                            </>
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider component="li" />
                                            </React.Fragment>
                                        ))}
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

        );
    }
}

Questions.defaultProps = {
    attendeeInfo: {},
    questionsList: [],
    sortedQuestionsList: [],
    qna_question: "",
    savingQuestion: false,
    newQuestions: [],
    orderBy: "ts"
};

const mapStateToProps = state => {
    return Object.keys(Questions.defaultProps).reduce(
        (obj, key) => ({ ...obj, [key]: state[key] }),
        {}
    );
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
