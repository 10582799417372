import React from "react";
import { connect } from "react-redux";

import { Paper, Typography, Box } from "@material-ui/core";
import { setState } from "../store/actions";
import { post } from "../lib/api";

class Sponsors extends React.Component {

    componentDidMount() {
        post("sponsors", {}).then(sponsorImg => {
            this.props.setState({ sponsorImg })
        });
    }

    render() {
        const { sponsorImg, smallSize } = this.props;
        return (
            <Paper style={{ paddingTop: "10px", ...(this.props.style || {}) }}>
                <Box component={smallSize ? "h3" : "h2"} style={{ textAlign: "center", marginBottom: smallSize ? "10px" : "20px" }}>Η Οικογένειά μας</Box>
                <Box style={{ height: smallSize ? "60px" : "100px", overflow: "hidden", position: "relative" }}>
                    <Box style={{
                        position: "absolute",
                        left: "0px", right: "0px", top: "0px", height: smallSize ? "60px" : "100px",
                    }}>
                        <div className="oz-crawler" style={{
                            whiteSpace: "nowrap",
                            position: "relative",
                            width: "max-content"
                        }}>
                            {sponsorImg.map((img, idx) =>
                                <img key={`1-${idx}`} style={{ imageRendering: "crisp-edges", height: smallSize ? "50px" : "80px", margin: "0px 20px", display: "inline-block" }} src={`/files/sponsors/${img}`} />
                            )}
                            {sponsorImg.map((img, idx) =>
                                <img key={`2-${idx}`} style={{ height: smallSize ? "50px" : "80px", margin: "0px 20px", display: "inline-block" }} src={`/files/sponsors/${img}`} />
                            )}
                        </div>
                    </Box>
                </Box>
            </Paper>
        );
    }
}

Sponsors.defaultProps = {
    sponsorImg: []
};

const mapStateToProps = state => {
    return Object.keys(Sponsors.defaultProps).reduce(
        (obj, key) => ({ ...obj, [key]: state[key] }),
        {}
    );
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sponsors);
