import React from "react";
import { connect } from "react-redux";

import { Typography } from "@material-ui/core";
import { setState } from "../store/actions";
import { post } from "../lib/api";
import Sponsors from "./Sponsors";

class Info extends React.Component {


    render() {
        const { attendeeInfo } = this.props;
        return (
            <div style={{
                lineHeight: "160%",
                fontSize: "16px",
                maxWidth: "900px",
                margin: "auto",
            }}>
                <p style={{ textAlign: "right", fontSize: "0.8em" }}>
                    <span style={{ display: "inline-block", textAlign: "left", lineHeight: "1.5em" }}>
                        {
                            attendeeInfo.name && <>
                                Συνδεθήκατε ως: <br /><b>{attendeeInfo.name} {attendeeInfo.surname}</b>
                            </>
                        }
                    </span>
                </p>
                <img src="/files/img/banner.png" style={{ width: "100%", marginBottom: "20px" }} />
                <p><b>Kαλώς ορίσατε στη 17η Γενική Συνέλευση του Σωματείου «ΔΙΑΖΩΜΑ», η οποία θα πραγματοποιηθεί το τετραήμερο από Πέμπτη 19 έως και Κυριακή 22 Σεπτεμβρίου 2024 στα αρχαία θέατρα της Αιτωλοακαρνανίας.</b></p>
                <p><b>Το κύριο μέρος των εργασιών της Γενικής Συνέλευσης θα πραγματοποιηθεί την Κυριακή 22 Σεπτεμβρίου 2024</b> και ώρα <b>10:00 π.μ. έως 15:30 μ.μ.</b> στο Δήμο Ιερής Πόλης Μεσολογγίου (στο αμφιθέατρο του Διοικητηρίου της Περιφέρειας) <b>με τίτλο: «ΑΙΤΩΛΟΑΚΑΡΝΑΝΙΑ: 14 χρόνια μετά»</b>, με τα παρακάτω θέματα:</p>
                <p>α) Έγκριση του απολογισμού δράσης του Διοικητικού Συμβουλίου για το έτος 2024.</p>
                <p>β) Έγκριση του οικονομικού και διαχειριστικού απολογισμού από 1η Ιανουαρίου έως την 31 η Αυγούστου του 2024.</p>
                <p>γ) Έγκριση του οικονομικού και διαχειριστικού απολογισμού από 1η Ιανουαρίου έως την 31 η Δεκεμβρίου του 2023.</p>
                <p>δ) Έγκριση του προγραμματισμού δράσεων για το έτος 2025.</p>
                <p>ε) Έγκριση του προϋπολογισμού για το έτος 2025.</p>
                <p>στ) Έγκριση της Έκθεσης της Ελεγκτικής Επιτροπής για το έτος 2023.</p>
                <p>Σας υπενθυμίζουμε ότι η Γενική μας Συνέλευση φέτος είναι εκλογική! Η εκλογική διαδικασία (εκλογή μελών νέου διοικητικού συμβουλίου και ελεγκτικής επιτροπής) θα ξεκινήσει στις 13:00 μ.μ., θα διεξάγεται παράλληλα με τις εργασίες της Γ.Σ. και θα ολοκληρωθεί στις 15:30 μ.μ.</p>
                <p><b>Σας παρακαλούμε θερμά την ημέρα της Γενικής μας Συνέλευσης να συνδεθείτε στην παρούσα σελίδα (με τον ίδιο σύνδεσμο) μισή ώρα πριν την έναρξη των εργασιών, δηλαδή στις 9:30 π.μ.</b></p>
                <p style={{ textAlign: "center" }}>Σας ευχαριστούμε για τη συμμετοχή σας και τη διαρκή σας στήριξη στο Σωματείο μας.</p>
                <Sponsors style={{ marginTop: "50px" }} />
            </div>
        );
    }
}

Info.defaultProps = {
    sponsorImg: [],
    sponsorImgStart: 0,
    attendeeInfo: {}
};

const mapStateToProps = state => {
    return Object.keys(Info.defaultProps).reduce(
        (obj, key) => ({ ...obj, [key]: state[key] }),
        {}
    );
};

function mapDispatchToProps(dispatch) {
    return {
        setState: state => dispatch(setState(state))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Info);
