import React from "react";
import { connect } from "react-redux";

import { setState } from "../store/actions";
import { IconButton } from "@material-ui/core";
import { loadData } from "../lib/api";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import DataTable from "./DataTable";
import ScreenShareIcon from '@material-ui/icons/ScreenShare';

class HandVoting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) { }

  componentDidMount() {
    loadData("data/handvoting", "handVotingList");
  }

  getColumns() {
    return [
      { id: "ord", label: "Order", align: "left", minWidth: "10px" },
      { id: "active", label: "Enabled", align: "left", minWidth: "100px", type: "checkbox" },
      { id: "question", label: "Question", align: "left", minWidth: "100px" },
      { id: "yes", label: "Yes", align: "left", minWidth: "100px", readonly: true },
      { id: "no", label: "No", align: "left", minWidth: "100px", readonly: true },
      { id: "comments", label: "Comments", align: "left", minWidth: "100px" },
    ];
  }

  getCustomField(row, column) {
    if (column.id === "slotBtn")
      return (
        <IconButton
          component="a"
          href={`/speaker-schedule/${row.hashkey}`}
          target="_blank">
          <InsertInvitationIcon />
        </IconButton>
      );

    return null;
  }


  render() {
    const columns = this.getColumns();
    const rows = [];

    return (
      <DataTable
        title="Hand Voting"
        titleButton={
          <IconButton
            component="a"
            href={`/pollresults`}
            target="_blank"
          >
            <ScreenShareIcon />
          </IconButton>
        }
        singular="Voting"
        columns={columns}
        list={this.props.handVotingList}
        getCustomField={(row, column) => this.getCustomField(row, column)}
        listName="handVotingList"
        crud="handvoting"
        searchFields={["question", "comments"]}
      />

    );
  }
}
HandVoting.defaultProps = {
  handVotingList: []
};

const mapStateToProps = state => {
  return {
    handVotingList: state.handVotingList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HandVoting);
