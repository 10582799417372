import React from "react";
import { connect } from "react-redux";

import { setState } from "../store/actions";
import { loadData } from "../lib/api";
import DataTable from "./DataTable";
import { IconButton } from "@material-ui/core";
import ScreenShareIcon from '@material-ui/icons/ScreenShare';

class Questions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {}

  componentDidMount() {
    loadData("data/questions", "questionsList");
  }

  getColumns() {
    return [
      {
        id: "ts",
        label: "ts",
        align: "left",
        minWidth: "10px",
        type: "datetime"
      },
      {
        id: "attendee",
        label: "Attendee",
        align: "left",
        minWidth: "100px",
        type: "custom",
        readonly: true
      },
      { id: "question", label: "Question", align: "left", minWidth: "100px" }
    ];
  }

  getCustomField(row, column) {
    if (column.id === "attendee") return `${row.attendee_name} ${row.attendee_surname}`
    return null;
  }

  render() {
    const columns = this.getColumns();
    const rows = [];

    return (
      <DataTable
        title="Questions"
        titleButton={
          <IconButton
            component="a"
            href={`/questionsviewer`}
            target="_blank"
          >
            <ScreenShareIcon />
          </IconButton>
        }
        singular="Question"
        columns={columns}
        list={this.props.questionsList}
        getCustomField={(row, column) => this.getCustomField(row, column)}
        listName="questionsList"
        crud="questions"
        searchFields={["question"]}
      />
    );
  }
}
Questions.defaultProps = {
  questionsList: []
};

const mapStateToProps = state => {
  return {
    questionsList: state.questionsList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setState: state => dispatch(setState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
